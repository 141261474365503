import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/Button';
import { Label } from 'components/label/Label';
import Radio from 'components/radio/Radio';
import Textarea from 'components/textarea/Textarea';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';
import { request } from 'utils/api';

interface VirtualFollowupsFormInputs {
  id?: number | string | null;
  satisfaction?: string;
  appetite_suppression?: string;
  adjust_medication_dose?: string;
  medication?: string;
  weight?: string | number;
  dose?: number | string;
  side_effects?: string;
  message_to_patient?: string;
  vf_interval_history?: string;
  vf_weight_loss_history?: string;
  switch_med_dose?: number | string;
  weight_trends?: string;
  name?: string;
  status?: string;
}

const handleUpdateVirtualFollowups = async (
  payload: VirtualFollowupsFormInputs,
  id: string
) => {
  const response = await request({
    url: `form-requests/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleCreateVirtualFollowups = async (
  payload: VirtualFollowupsFormInputs
) => {
  const response = await request({
    url: 'form-requests',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function VirtualFollowupsForm({
  info,
  setIsClose
}: {
  info?: VirtualFollowupsResponse;
  setIsClose: any;
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields }
  } = useForm<VirtualFollowupsFormInputs>({
    values: info
      ? {
          medication: info.medication,
          name: info.name,
          weight: info.weight,
          dose: info.dose,
          id: info.id,
          satisfaction: info.satisfaction,
          appetite_suppression: info.appetite_suppression,
          adjust_medication_dose: info.adjust_medication_dose,
          weight_trends: info.weight_trends,
          side_effects: info.side_effects,
          message_to_patient: info.message_to_patient,
          vf_interval_history: info.vf_interval_history,
          vf_weight_loss_history: info.vf_weight_loss_history,
          switch_med_dose: ''
        }
      : {
          medication: '',
          name: '',
          weight: '',
          dose: '',
          id: null,
          satisfaction: '',
          appetite_suppression: '',
          adjust_medication_dose: '',
          weight_trends: '',
          side_effects: '',
          message_to_patient: '',
          vf_interval_history: '',
          vf_weight_loss_history: '',
          switch_med_dose: ''
        }
  });

  const adjustMedicationDose = watch('dose');
  const selectedMedication = watch('medication');

  const { mutateAsync: updateVirtualFollowups, isPending: isUpdatePending } =
    useMutation({
      mutationFn: (data: VirtualFollowupsFormInputs) =>
        handleUpdateVirtualFollowups(data, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['virtual_followups'] });
          queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
          setIsClose();
        }
      }
    });

  const { mutateAsync: createVirtualFollowups, isPending: isCreatePending } =
    useMutation({
      mutationFn: (data: VirtualFollowupsFormInputs) =>
        handleCreateVirtualFollowups(data),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['virtual_followups'] });
          setIsClose();
        }
      }
    });

  const onSubmit = (data: VirtualFollowupsFormInputs) => {
    if (data?.switch_med_dose) {
      data = { ...data, dose: data?.switch_med_dose };
      delete data.switch_med_dose;
    }
    let dirtyData: Partial<VirtualFollowupsFormInputs> = {};

    Object.keys(data).forEach((key) => {
      const objKey = key as keyof VirtualFollowupsFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (info) {
      dirtyData = { ...dirtyData, status: 'Reviewed' };
      updateVirtualFollowups(dirtyData);
    } else {
      dirtyData = { ...dirtyData, status: 'Pending' };
      createVirtualFollowups({ ...dirtyData, id: id });
    }
  };

  const isPending = isUpdatePending || isCreatePending;
  type DoseOption = {
    value: string;
    label: string;
  };

  function getDoseOptions(
    medication: string,
    currentDose: number | null
  ): DoseOption[] {
    let doseOptions =
      medication === 'semaglutide'
        ? semaglutideDoseOptions
        : tirzepatideDoseOptions;
    doseOptions = doseOptions.sort((a, b) => Number(a.value) - Number(b.value));

    if (currentDose === null) {
      currentDose = Number(doseOptions[0].value);
    }

    const currentIndex =
      doseOptions.findIndex(
        (option) => Number(option.value) === Number(currentDose)
      ) < 0
        ? 0
        : doseOptions.findIndex(
            (option) => Number(option.value) === Number(currentDose)
          );
    let result: DoseOption[] = [];

    if (currentIndex < doseOptions.length - 1) {
      result.push({
        value: doseOptions[currentIndex + 1].value,
        label: `Increase to ${doseOptions?.[currentIndex + 1]?.value} mg`
      });
    }

    result.push({
      value: doseOptions?.[currentIndex]?.value,
      label: `Continue with ${doseOptions?.[currentIndex]?.value} mg`
    });

    if (currentIndex > 0) {
      result.push({
        value: doseOptions[currentIndex - 1].value,
        label: `Decrease to ${doseOptions?.[currentIndex - 1]?.value} mg`
      });
    }

    return result;
  }

  const semaglutideDoseOptions = [
    { value: '0.25', label: '0.25 mg' },
    { value: '0.5', label: '0.5 mg' },
    { value: '1', label: '1.0  mg' },
    { value: '1.7', label: '1.7 mg' },
    { value: '2.4', label: '2.4 mg' }
  ];
  const tirzepatideDoseOptions = [
    { value: '2.5', label: '2.5 mg' },
    { value: '5.0', label: '5.0 mg' },
    { value: '7.5', label: '7.5 mg' },
    { value: '12.5', label: '12.5 mg' },
    { value: '15.0', label: '15.0 mg' }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          <p className='text-base font-semibold border-b mr-9 pb-2'>
            Patient Name - {info?.name}
          </p>
          <div className='overflow-y-auto h-[41rem] m-3 ml-0'>
            <div className='mb-6'>
              <Label className='!font-normal'>Interval History</Label>
              <Controller
                name='vf_interval_history'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={12} className='mt-2' {...field} />
                )}
              />
            </div>
            <div className='mb-6'>
              <Label className='!font-normal'>Weight Loss History</Label>
              <Controller
                name='vf_weight_loss_history'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={12} className='mt-2' {...field} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <span className='text-base font-semibold pb-2'>Review</span>
            <ArrowRightOutlined
              onClick={() => setIsClose()}
              className='text-lg pr-4 pb-3'
            />
          </div>
          <div className='mt-3.5 mb-20'>
            <div className='grid grid-cols-2 mb-7'>
              <div>
                <span className='text-slate-500'>Satisfaction - </span>
                <span className='text-gray-800'>{info?.satisfaction}</span>
              </div>
              <div>
                <span className='text-slate-500'>Appetite suppression - </span>
                <span className='text-gray-800'>
                  {info?.appetite_suppression}
                </span>
              </div>
            </div>
            <div className='grid grid-cols-3'>
              <div className='mb-6'>
                <Label className='!font-normal'>Adjust Medication Dose</Label>
                <Controller
                  name='dose'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        ...getDoseOptions(
                          String(info?.medication),
                          Number(info?.dose)
                        ),
                        {
                          label: 'Switch Medication',
                          value: 'switch_medication'
                        }
                      ]}
                    />
                  )}
                />
              </div>
              {adjustMedicationDose === 'switch_medication' && (
                <div className='w-36'>
                  <Label className='!font-normal'>Select Medication</Label>
                  <Controller
                    name='medication'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          { value: 'semaglutide', label: 'Semaglutide' },
                          { value: 'tirzepatide', label: 'Tirzepatide' }
                        ]}
                      />
                    )}
                  />
                </div>
              )}
              {adjustMedicationDose === 'switch_medication' &&
                selectedMedication && (
                  <div className='w-44'>
                    <Label className='!font-normal'>Dose</Label>
                    <Controller
                      name='switch_med_dose'
                      control={control}
                      render={({ field }) => (
                        <Radio
                          className='w-full mt-2 h-[38px]'
                          {...field}
                          options={
                            selectedMedication === 'semaglutide'
                              ? semaglutideDoseOptions
                              : tirzepatideDoseOptions
                          }
                        />
                      )}
                    />
                  </div>
                )}
              <div className='grid grid-col'>
                <div className='mb-5'>
                  <Label className='!font-normal'>Weight Trends</Label>
                  <Controller
                    name='weight'
                    disabled
                    control={control}
                    render={({ field }) => (
                      <Textarea type='text' className='mt-2' {...field} />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className='mb-5'>
              <Label className='!font-normal'>Side Effects</Label>
              <Controller
                name='side_effects'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={6} className='mt-2' {...field} />
                )}
              />
            </div>
            <div className='mb-5'>
              <Label className='!font-normal'>Message to Patient</Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={6} className='mt-2' {...field} />
                )}
              />
            </div>
          </div>
          <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
            <button
              type='button'
              className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
              onClick={() => setIsClose()}
            >
              Cancel
            </button>
            <Button className='!w-fit py-3 px-8' isLoading={isPending}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
