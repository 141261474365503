import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import {
  EyeOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { InitialConsultationsResponse } from 'types/initialConsultations';
import InitialConsultationsForm from './components/InitialConsultationsForm';
import { Modal, Spin } from 'antd';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import Select from 'components/select/Select';
import TextArea from 'antd/es/input/TextArea';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import Tooltip from 'components/tooltip/Tooltip';

interface InitialConsultationsFilter {
  form_id: string;
  name: string;
  status: string;
}

const getInitialConsultations = async (
  currentPage: number,
  params?: InitialConsultationsFilter
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: { page: currentPage, ...params }
  });
  return data;
};

const handleSendTaskMessage = async (
  payload: { task_message: string },
  Id: string
) => {
  const response = await request({
    url: `form-requests/send-task-email/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleArchiveData = async (payload: { status: string }, Id: string) => {
  const response = await request({
    url: `form-requests/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export default function InitialConsultations() {
  const [currentPage, setCurrentPage] = useState(1);
  const [initialConsultationFilters, setInitialConsultationFilters] = useState({
    form_id: '64442c2f9505ead402eafa03',
    name: '',
    status: ''
  });
  const [isMessageInputModalOpen, setIsMessageInputModalOpen] = useState(false);
  const [taskMessage, setTaskMessage] = useState('');
  const [editingRecord, setEditingRecord] = useState(false);
  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm<InitialConsultationsFilter>({
    defaultValues: {}
  });

  const { data: InitialConsultationsData, isLoading } = useQuery({
    queryKey: [
      'initial_consultations',
      currentPage,
      initialConsultationFilters
    ],
    queryFn: () =>
      getInitialConsultations(currentPage, initialConsultationFilters)
  });

  const { mutateAsync: archiveData } = useMutation({
    mutationFn: (id: string) => handleArchiveData({ status: 'Archive' }, id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['archive_data'] });
      queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
    }
  });

  const { mutateAsync: sendTaskMessageEmail, isPending: isSending } =
    useMutation({
      mutationFn: () =>
        handleSendTaskMessage(
          { task_message: taskMessage },
          String(editingRecord)
        ),
      onSuccess() {
        setTaskMessage('');
        setIsMessageInputModalOpen(false);
        setEditingRecord(false);
        queryClient.invalidateQueries({ queryKey: ['send_email'] });
        queryClient.refetchQueries({ queryKey: ['send_email'] });
      }
    });

  const handleCreateTaskClick = (id: any) => {
    setEditingRecord(id);
    setIsMessageInputModalOpen(true);
  };

  const initialConsultationsMemorizedData = useMemo(() => {
    return InitialConsultationsData?.form_requests ?? [];
  }, [InitialConsultationsData?.form_requests]);

  const onSubmit: SubmitHandler<InitialConsultationsFilter> = (data) => {
    setInitialConsultationFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Initial Consultations</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input placeholder='Name' type='text' {...field} />
              )}
            />
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Select Status'
                  {...field}
                  className='w-[150px] h-[38px]'
                  options={[
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Reviewed', value: 'Reviewed' },
                    { label: 'Archive', value: 'Archive' }
                  ]}
                />
              )}
            />
            <Button className='w-max px-3.5 h-[38px]'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  form_id: '64442c2f9505ead402eafa03',
                  name: '',
                  status: undefined
                })
              }
              className='border border-orange rounded py-2.5 px-3'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={initialConsultationsMemorizedData}
            columns={columns(handleCreateTaskClick, archiveData)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={InitialConsultationsData?.pagination.total_count}
        totalPage={InitialConsultationsData?.pagination.total_pages}
        perPage={InitialConsultationsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal
        open={isMessageInputModalOpen}
        onCancel={() => setIsMessageInputModalOpen(false)}
        footer={[]}
      >
        <div className='px-5 py-2'>
          <h2 className='text-lg font-semibold text-orange mb-3'>
            Add Message
          </h2>
          <TextArea
            value={taskMessage}
            onChange={(e) => setTaskMessage(e.target.value)}
          />
        </div>
        <button
          disabled={!taskMessage}
          type='submit'
          className={`!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold ml-40 ${!taskMessage && 'disabled:opacity-50 disabled:cursor-not-allowed'}`}
          onClick={() => sendTaskMessageEmail()}
        >
          {isSending ? <Spin /> : 'Submit'}
        </button>
      </Modal>
    </div>
  );
}

const columns = (
  handleCreateTaskClick: (id: string) => void,
  archiveData: (id: string) => void
): ColumnDef<InitialConsultationsResponse>[] => [
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: 'Date Submitted',
    accessorKey: 'completed_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Service',
    accessorKey: 'service',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Preferred Medication',
    accessorKey: 'preferred_medications',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Preferred Plan',
    accessorKey: 'preferred_plan'
  },
  {
    header: 'Draft Date',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => (
      <span className='text-neutral-700'>{info.getValue() as string}</span>
    )
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex items-center gap-3 text-base'>
        <button
          className='text-orange cursor-pointer whitespace-nowrap font-medium mr-0.5'
          onClick={() => handleCreateTaskClick(String(info.getValue()))}
        >
          <Tooltip title='Send email.'>
            <FileAddOutlined className='cursor-pointer' />
          </Tooltip>
        </button>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='90%'
          button={
            <Tooltip title='Review details.'>
              <EyeOutlined className='cursor-pointer' />
            </Tooltip>
          }
          closeIcon={null}
        >
          <InitialConsultationsForm info={info.row.original} setIsClose={''} />
        </Drawer>
        <PopupConfirm
          title='Archive Record'
          description='Are you sure you want to archive?'
          onConfirm={() => archiveData(String(info.getValue()))}
        >
          <Tooltip title='Move to archives.'>
            <FolderAddOutlined className='text-orange cursor-pointer' />
          </Tooltip>
        </PopupConfirm>
      </div>
    )
  }
];
