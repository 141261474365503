import { ArrowLeftOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar } from 'antd';
import Button from 'components/button/Button';
import { Link, useParams } from 'react-router-dom';
import { ProfileResponse } from 'types/profile';
import { request } from 'utils/api';

const getProfile = async (id: string | undefined) => {
  return await request({
    url: 'users/logged-in',
    method: 'GET'
  });
};

export default function Profile() {
  const { id } = useParams();

  const { data: profileData } = useQuery<ProfileResponse>({
    queryKey: ['profile', id],
    queryFn: () => getProfile(id)
  });

  const profileDetails = [
    {
      label: 'Name',
      value: `${profileData?.first_name || ''} ${profileData?.last_name || ''}`
    },
    { label: 'Email', value: profileData?.email || 'N/A' },
    { label: 'Phone Number', value: profileData?.phone_number || 'N/A' },
    { label: 'Role', value: profileData?.role || 'N/A' },
    { label: 'Username', value: profileData?.user_name || 'N/A' },
    {
      label: 'PB Consultant Id',
      value: profileData?.pb_consultant_id || 'N/A'
    },
    {
      label: 'Practitioner',
      value: profileData?.is_practitioner ? 'Yes' : 'No'
    },
    { label: 'Assistant', value: profileData?.is_assistant ? 'Yes' : 'No' }
  ];

  return (
    <div className='p-5 bg-[#FAFBFC] h-[45rem]'>
      <div className='flex justify-between'>
        <Link to='/customers'>
          <Button className='flex gap-2 items-center !w-fit px-3 bg-transparent border border-orange !text-orange'>
            <ArrowLeftOutlined />
            Back
          </Button>
        </Link>
        <div className='mx-auto'>
          <div className='h-35 w-35 rounded-full shadow-lg mr-11'>
            <Avatar
              className='w-20 h-20 block mx-auto'
              src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
            />
          </div>
        </div>
      </div>
      <div className='col-span-2 rounded-xl border border-light-grey w-[35rem] h-[35rem] mx-auto mt-5 p-5 bg-white shadow-lg'>
        <span className='font-semibold text-orange'>Profile Details</span>
        <div className='h-[30rem] overflow-auto mt-3'>
          {profileDetails.map(({ label, value }, index) => (
            <div key={index} className='border-b mt-3 p-2'>
              <span className='font-light mr-2 text-orange'>{label}: </span>
              <span className='font-medium'>{value}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
