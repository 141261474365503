import PrivateRoute from 'components/appGuards/PrivateRoutes';
import { Home } from 'pages/Home';
import Login from 'pages/auth/Login';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import paths from './path';
import CustomerPage from 'pages/customer/CustomerPage';
import CustomersPage from 'pages/customers/CustomersPage';
import ChatBotPage from 'pages/chatbot/ChatbotPage';
import KnowledgeBasePage from 'pages/knowledgeBase/KnowledgeBasePage';
import KnowledgeBaseForm from 'pages/knowledgeBase/components/KnowledgeBaseForm';
import IntentsPage from 'pages/intents/IntentsPage';
import KnowledgeBaseApprovalsPage from 'pages/knowledgeBaseApprovals/KnowledgeBaseApprovalsPage';
import OrdersPage from 'pages/orders/Orders';
import RetrievalTesting from 'pages/retrievalTesting/RetrievalTesting';
import CheckInsPage from 'pages/checkins/CheckIns';
import SmsLogsPage from 'pages/smsLogs/SmsLogsPage';
import AiPromptsPage from 'pages/aiPrompts/AiPrompts';
import AiPromptForm from 'pages/aiPrompts/components/AiPromptForm';
import FormRequest from 'pages/formRequest/FormRequest';
import InitialConsultations from 'pages/initialConsultations/InitialConsultations';
import VirtualFollowups from 'pages/virtualFollowups/VirtualFollowups';
import EmailLogsPage from 'pages/emailLogs/EmailLogsPage';
import Logs from 'pages/logs/Logs';
import Profile from 'pages/profile/Profile';

export const routes = createBrowserRouter([
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Home />
      </PrivateRoute>
    ),
    children: [
      {
        path: paths.profile,
        element: <Profile />
      },
      {
        index: true,
        element: <Navigate replace={true} to={paths.customers} />
      },
      { path: paths.customers, element: <CustomersPage /> },
      { path: paths.orders, element: <OrdersPage /> },
      { path: paths.checkins, element: <CheckInsPage /> },
      { path: paths.customer(), element: <CustomerPage /> },
      {
        path: paths.logs,
        element: <Logs />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.smsLogs} />
          },
          { path: paths.smsLogs, element: <SmsLogsPage /> },
          { path: paths.emailLogs, element: <EmailLogsPage /> }
        ]
      },
      {
        path: paths.chatbot,
        element: <ChatBotPage />,
        children: [
          {
            index: true,
            element: <Navigate replace={true} to={paths.knowledgeBase} />
          },
          { path: paths.knowledgeBase, element: <KnowledgeBasePage /> },
          { path: paths.retrievalTesting, element: <RetrievalTesting /> },
          { path: paths.addKnowledgeBase, element: <KnowledgeBaseForm /> },
          { path: paths.editKnowledgeBase(), element: <KnowledgeBaseForm /> },
          { path: paths.intents, element: <IntentsPage /> },
          {
            path: paths.knowledgeBaseApprovals,
            element: <KnowledgeBaseApprovalsPage />
          }
        ]
      },
      {
        path: paths.aiPrompts,
        element: <AiPromptsPage />
      },
      { path: paths.editAiPrompts(), element: <AiPromptForm /> },
      {
        path: paths.formRequest,
        element: <FormRequest />
      },
      {
        path: paths.initialConsultations,
        element: <InitialConsultations />
      },
      {
        path: paths.virtualFollowups,
        element: <VirtualFollowups />
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  }
]);
