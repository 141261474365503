import { ArrowRightOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/Button';
import { Label } from 'components/label/Label';
import Radio from 'components/radio/Radio';
import Textarea from 'components/textarea/Textarea';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InitialConsultationsResponse } from 'types/initialConsultations';
import { request } from 'utils/api';
import formatDate from 'utils/helpers/date';

interface InitialConsultationFormInputs {
  completed_at?: string;
  message_to_patient?: string;
  medication?: string;
  name?: string;
  preferred_medications?: string;
  preferred_plan?: string;
  send_review?: boolean | string;
  submit?: boolean | string;
  weight?: string | number;
  dose?: number | string;
  id?: number | string | null;
  ic_assessment_and_plan?: string;
  ic_hpi?: string;
  ic_pmh?: string;
  status?: string;
}

const handleUpdateInitialConsultations = async (
  payload: InitialConsultationFormInputs,
  Id: string
) => {
  const response = await request({
    url: `form-requests/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleCreateInitialConsultations = async (
  payload: InitialConsultationFormInputs
) => {
  const response = await request({
    url: 'form-requests',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function InitialConsultationsForm({
  info,
  setIsClose
}: {
  info?: InitialConsultationsResponse;
  setIsClose: any;
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors }
  } = useForm<InitialConsultationFormInputs>({
    values: info
      ? {
          completed_at: formatDate(info.completed_at),
          medication: info.medication,
          name: info.name,
          preferred_medications: info.preferred_medications,
          preferred_plan: info.preferred_plan,
          send_review: info.send_review,
          submit: info.submit,
          weight: info.weight,
          dose: info.dose,
          id: info.id,
          message_to_patient: info.message_to_patient,
          ic_assessment_and_plan: info.ic_assessment_and_plan,
          ic_hpi: info.ic_hpi,
          ic_pmh: info.ic_pmh,
          status: 'Reviewed'
        }
      : {
          completed_at: '',
          medication: '',
          name: '',
          preferred_medications: '',
          preferred_plan: '',
          send_review: false,
          submit: false,
          weight: '',
          dose: '',
          id: null,
          message_to_patient: '',
          ic_assessment_and_plan: '',
          ic_hpi: '',
          ic_pmh: '',
          status: 'Reviewed'
        }
  });
  const selectedMedication = watch('medication');
  const {
    mutateAsync: updateInitialConsultations,
    isPending: isUpdatePending
  } = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleUpdateInitialConsultations(data, String(info?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        reset();
        queryClient.invalidateQueries({
          queryKey: ['initial_consultations']
        });
        queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
        setIsClose();
      }
    }
  });

  const {
    mutateAsync: createInitialConsultations,
    isPending: isCreatePending
  } = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleCreateInitialConsultations(data),
    onSuccess(data) {
      if (data !== undefined) {
        reset();
        queryClient.invalidateQueries({
          queryKey: ['initial_consultations']
        });
        setIsClose();
      }
    }
  });

  const onSubmit = (data: InitialConsultationFormInputs) => {
    let dirtyData: Partial<InitialConsultationFormInputs> = {};

    Object.keys(data).forEach((key) => {
      const objKey = key as keyof InitialConsultationFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (info) {
      dirtyData = { ...dirtyData, status: 'Reviewed' };
      updateInitialConsultations(dirtyData);
    } else {
      dirtyData = { ...dirtyData, status: 'Reviewed' };
      createInitialConsultations({ ...dirtyData, id: id });
    }
  };

  const isPending = isUpdatePending || isCreatePending;

  const semaglutideDoseOptions = [
    { value: '0.25', label: '0.25 mg' },
    { value: '0.5', label: '0.5 mg' },
    { value: '1', label: '1.0 mg' },
    { value: '1.7', label: '1.7 mg' },
    { value: '2.4', label: '2.4 mg' }
  ];
  const tirzepatideDoseOptions = [
    { value: '2.5', label: '2.5 mg' },
    { value: '5.0', label: '5.0 mg' },
    { value: '7.5', label: '7.5 mg' },
    { value: '12.5', label: '12.5 mg' },
    { value: '15.0', label: '15.0 mg' }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          <p className='text-base font-semibold border-b mr-9 pb-2'>
            Patient Name - {info?.name}
          </p>
          <div className='overflow-y-auto h-[41rem] m-3 ml-0'>
            <div className='mb-6'>
              <Label className='!font-normal'>HPI</Label>
              <Controller
                name='ic_hpi'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' className='mt-2' row={12} {...field} />
                )}
              />
            </div>
            <div className='mb-6'>
              <Label className='!font-normal'>PMH</Label>
              <Controller
                name='ic_pmh'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={12} className='mt-2' {...field} />
                )}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <span className='text-base font-semibold pb-2'>Edit Details</span>
            <ArrowRightOutlined
              onClick={() => setIsClose()}
              className='text-lg pr-4 pb-3'
            />
          </div>
          <div className='mt-3.5 gap-6 mb-20'>
            <div className='grid grid-cols-2 mb-7'>
              <div>
                <span className='text-slate-500'>Preferred Medication - </span>
                <span className='text-gray-800'>
                  {info?.preferred_medications}
                </span>
              </div>
              <div>
                <span className='text-slate-500'>Preferred Plan - </span>
                <span className='text-gray-800'>{info?.preferred_plan}</span>
              </div>
            </div>
            <div className='grid grid-cols-3 mb-6'>
              <div>
                <Label className='!font-normal'>Order Medication</Label>
                <Controller
                  name='medication'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: 'semaglutide', label: 'Semaglutide' },
                        { value: 'tirzepatide', label: 'Tirzepatide' }
                      ]}
                    />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.medication?.message}
                </p>
              </div>
              <div className='w-72'>
                <Label className='!font-normal'>Dose</Label>
                <Controller
                  name='dose'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={
                        selectedMedication === 'semaglutide'
                          ? semaglutideDoseOptions
                          : tirzepatideDoseOptions
                      }
                    />
                  )}
                />
              </div>
              <div className='w-48 pl-20'>
                <Label className='!font-normal'>Send Review</Label>
                <Controller
                  name='send_review'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className='mb-6'>
              <Label className='!font-normal'>Message to Patient</Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={7} className='mt-2' {...field} />
                )}
              />
            </div>
            <div>
              <Label className='!font-normal'>Assessment & Plan</Label>
              <Controller
                name='ic_assessment_and_plan'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={7} className='mt-2' {...field} />
                )}
              />
            </div>
          </div>
          <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
            <button
              type='button'
              className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
              onClick={() => setIsClose()}
            >
              Cancel
            </button>
            <Button className='!w-fit py-3 px-8' isLoading={isPending}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
