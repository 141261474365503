import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Drawer from 'components/drawer/Drawer';
import {
  EyeOutlined,
  FolderAddOutlined,
  LinkOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';
import VirtualFollowupsForm from './components/VirtualFollowupsForm';
import formatDate from 'utils/helpers/date';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import paths from 'constants/path';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import Tooltip from 'components/tooltip/Tooltip';

interface VirtualFollowupsFilter {
  form_id: string;
  name: string;
  status: string;
}

const getVirtualFollowups = async (
  currentPage: number,
  params?: VirtualFollowupsFilter
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: { page: currentPage, ...params }
  });
  return data;
};

const handleArchiveData = async (payload: { status: string }, Id: string) => {
  const response = await request({
    url: `form-requests/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

export default function VirtualFollowups() {
  const [currentPage, setCurrentPage] = useState(1);
  const [virtualFollowupsFilters, setVirtualFollowupsFilters] = useState({
    form_id: '6517100f59761126ff10ba53',
    name: '',
    status: ''
  });
  const queryClient = useQueryClient();

  const { data: VirtualFollowupsData, isLoading } = useQuery({
    queryKey: ['virtual_followups', currentPage, virtualFollowupsFilters],
    queryFn: () => getVirtualFollowups(currentPage, virtualFollowupsFilters)
  });

  const { mutateAsync: archiveData } = useMutation({
    mutationFn: (id: string) => handleArchiveData({ status: 'Archive' }, id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['archive_data'] });
      queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
    }
  });

  const { control, handleSubmit, reset } = useForm<VirtualFollowupsFilter>({
    defaultValues: {}
  });

  const initialConsultationsMemorizedData = useMemo(() => {
    return VirtualFollowupsData?.form_requests ?? [];
  }, [VirtualFollowupsData?.form_requests]);

  const onSubmit: SubmitHandler<VirtualFollowupsFilter> = (data) => {
    setVirtualFollowupsFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Virtual Follow-ups</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <Controller
              name='name'
              control={control}
              render={({ field }) => (
                <Input placeholder='Name' type='text' {...field} />
              )}
            />
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Select Status'
                  {...field}
                  className='w-[150px] h-[38px]'
                  options={[
                    { label: 'Pending', value: 'Pending' },
                    { label: 'Reviewed', value: 'Reviewed' },
                    { label: 'Archive', value: 'Archive' }
                  ]}
                />
              )}
            />
            <Button className='w-max px-3.5 h-[38px]'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  form_id: '64442c2f9505ead402eafa03',
                  name: '',
                  status: undefined
                })
              }
              className='border border-orange rounded py-2.5 px-3'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={initialConsultationsMemorizedData}
            columns={columns(archiveData)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={VirtualFollowupsData?.pagination.total_count}
        totalPage={VirtualFollowupsData?.pagination.total_pages}
        perPage={VirtualFollowupsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  archiveData: (id: string) => void
): ColumnDef<VirtualFollowupsResponse>[] => [
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: 'Follow-up Date',
    accessorKey: 'followup_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Current Medication',
    accessorKey: 'current_medication',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Weight(lbs)',
    accessorKey: 'weight'
  },
  {
    header: 'Satisfaction',
    accessorKey: 'satisfaction'
  },
  {
    header: 'Form Completed',
    accessorKey: 'completed',
    cell: (info) => (info.row.original.completed ? 'Yes' : 'No')
  },
  {
    header: 'Draft Date',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => (
      <span className='text-neutral-700'>{info.getValue() as string}</span>
    )
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex items-center gap-3 text-base'>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='90%'
          button={
            <Tooltip title='Review details.'>
              <EyeOutlined className='mr-1 cursor-pointer' />
            </Tooltip>
          }
          closeIcon={null}
        >
          <VirtualFollowupsForm info={info.row.original} setIsClose={''} />
        </Drawer>
        <Link
          to={info.row.original.form_pb_link}
          target='_blank'
          className='text-orange underline-offset-4'
        >
          <Tooltip title='Redirect to Practice Better.'>
            <LinkOutlined className='cursor-pointer' />
          </Tooltip>
        </Link>
        <PopupConfirm
          title='Archive Record'
          description='Are you sure you want to archive?'
          onConfirm={() => archiveData(String(info.getValue()))}
        >
          <Tooltip title='Move to archives.'>
            <FolderAddOutlined className='text-orange cursor-pointer' />
          </Tooltip>
        </PopupConfirm>
      </div>
    )
  }
];
